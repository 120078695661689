/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";

import classnames from "classnames";

import Button from "@components/common/Button";

import useModal from "@hooks/useModal";

const Modal: FC<PropsWithChildren> = ({ children }) => {
  const body = useRef<HTMLElement>();

  const { toggleModal, isOpen, modalBody } = useModal();

  const handleClose = (): void => toggleModal();

  const handleEscPress = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === "Escape" && isOpen && !modalBody?.closeCrossOnly) {
        toggleModal();
      }
    },
    [isOpen, modalBody?.closeCrossOnly, toggleModal]
  );

  useEffect(() => {
    if (!modalBody?.closeCrossOnly) {
      document.addEventListener("keydown", handleEscPress, false);
    }

    return () => {
      document.removeEventListener("keydown", handleEscPress, false);
    };
  }, [handleEscPress, modalBody?.closeCrossOnly]);

  useEffect(() => {
    body.current = document.body;
    body.current.classList.remove("modal-open");

    return () => {
      body?.current?.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      body.current?.classList.add("modal-open");
    } else {
      body.current?.classList.remove("modal-open");
    }

    if (!isOpen) {
      body?.current?.classList.remove("modal-open");
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <div className={classnames("modal", { "open-modal": isOpen })}>
        <div className="modal-container">
          <div className="top">
            <Button
              className="close-btn"
              icon="cross"
              color="link-secondary"
              iconClassName="close-btn-icon"
              onClick={handleClose}
            />
          </div>
          <div className="body">{modalBody?.body || children}</div>
        </div>

        <Button
          className="close-btn"
          icon="cross"
          color="link-secondary"
          iconClassName="close-btn-icon"
          onClick={handleClose}
        />
      </div>

      <div
        className="overlay"
        onClick={() => {
          if (!modalBody?.closeCrossOnly) {
            handleClose();
          }
        }}
      />
    </React.Fragment>
  );
};

export default Modal;
