import React, { FC, useState } from "react";

import axios from "axios";
import * as yup from "yup";

import { useFormik } from "formik";
import { Link } from "gatsby";

import Icon from "@components/common/Icon";
import Spinner from "@components/common/Spinner";

import FacebookIcon from "@assets/svg/social/facebook.svg";
import InstagramIcon from "@assets/svg/social/instagram.svg";
import TikTokIcon from "@assets/svg/social/tik-tok.svg";

import paymentMethods from "../images/payment-methods.png";

interface FormValues {
  email: string;
}

const footerLinksSections = [
  {
    sectionTitle: "Company",
    links: [
      {
        title: "Our Story",
        to: "/about-us",
      },
      {
        title: "Order Tracking",
        to: "/track-order",
      },
      {
        title: "Contact Us",
        to: "/contact-us",
      },
      {
        title: "Products",
        to: "/shop",
      },
    ],
  },
  {
    sectionTitle: "Information",
    links: [
      {
        title: "Refund Policy",
        to: "/faqs/#refund-policy",
      },
      {
        title: "Shipping & Handling",
        to: "/faqs/#shipping-and-delivery",
      },
      {
        title: "FAQs",
        to: "/faqs",
      },
    ],
  },
];

const validationSchema = yup
  .object()
  .shape({ email: yup.string().email("Invalid email address").required("Email is required") });

const Footer: FC = () => {
  const initialValues: FormValues = { email: "" };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    setIsLoading(true);

    await axios
      .get(`${window.location.origin}/api/subscribe/${values.email}`)
      .then(() => {
        setIsLoading(false);
        setIsSubmitted(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error.response);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <footer className="footer-container">
      <div className="main-footer">
        <div className="company-info">
          <Icon name="logo" className="logo" />
          <p className="description">
            Groove is a company built for THC Enthusiasts, by THC Connoisseurs
          </p>
          <p className="contacts">
            <span>support@officialgroove.com</span>
            <span>877-298-5058</span>
          </p>
        </div>

        <div className="newsletter">
          <p className="title">Newsletter</p>
          <p className="description">
            Join Our Mailing List For Awesome Updates & Unlock Special Discounts!
          </p>
          <form onSubmit={formik.handleSubmit} className="input-group-form">
            <input
              type="text"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Your Email Address"
              disabled={isSubmitted}
            />
            <button type="submit" disabled={isSubmitted}>
              <div className="icon">{isLoading ? <Spinner /> : <Icon name="sm-arrow-right" />}</div>
            </button>
          </form>
          <div className="message-container">
            {formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ) : null}

            {isSubmitted ? <div className="success-message">Done!</div> : null}
          </div>

          <div className="social-links-container">
            <a
              href="https://www.instagram.com/getgroove/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61551055355932&mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FacebookIcon />
            </a>
          </div>
        </div>
        <div className="links-sections">
          {footerLinksSections.map(({ sectionTitle, links }) => (
            <div className="links-section" key={sectionTitle}>
              <p className="section-title">{sectionTitle}</p>
              <div className="links">
                {links.map(({ title, to }) => (
                  <Link to={to} key={title} className="section-link">
                    {title}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="disclaimer">
        <p>
          These statements have not been evaluated by the FDA. The products offered for sale on this
          site are not intended to diagnose, treat, cure, mitigate or prevent any disease and/or
          affect any structure or function of the human body.
        </p>
        <h6>State Legality</h6>
        <p>
          Regulations surrounding the products offered on this site are constantly changing. We ship
          our products into states where they are lawful.
        </p>
        <p>
          Additionally, we reserve the right to amend, alter, or change the list of states we do not
          ship to at our discretion and without notice to users of this site. Any purchaser of
          products offered for sale on this site assumes all risk and liability associated with the
          purchase, possession, and use of these products
        </p>
      </div>
      <div className="sub-footer">
        <span className="company">{`Groove © ${new Date().getFullYear()}`}</span>
        <div className="payments-container">
          <span>Pay securely with</span>
          <img
            src={paymentMethods}
            alt="payment methods"
            className="payment-methods"
            width={304}
            height={30}
          />
        </div>
        <div className="links">
          <span className="company">{`Groove © ${new Date().getFullYear()}`}</span>
          <Link to="/terms-and-conditions" className="sub-footer-link">
            Terms & Conditions
          </Link>
          <Link to="/privacy-policy" className="sub-footer-link">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
