import React, { FC, useState } from "react";

import classnames from "classnames";

import Button from "@components/common/Button";
import Icon from "@components/common/Icon";

type StarsRating = {
  currentRating: number;
  disabled?: boolean;
};

const StarsRating: FC<StarsRating> = ({ currentRating, disabled }) => {
  const [rating, setRating] = useState<number>(currentRating);
  const [hover, setHover] = useState<number>(currentRating);

  const changeRating = (number: number): void => {
    setRating(number);
  };

  return (
    <div className={classnames("star-rating", { disabled })}>
      {[1, 2, 3, 4, 5].map((star, index) => {
        if (disabled) {
          return <Icon name="star" key={index} />;
        }

        return (
          <Button
            key={star}
            className={classnames("star-button", {
              off: index >= (hover || rating),
              on: index >= (hover || rating),
            })}
            onClick={() => !disabled && changeRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
            color="link-secondary"
            icon="star"
            iconClassName="star-icon"
          />
        );
      })}
    </div>
  );
};

export default StarsRating;
