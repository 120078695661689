import { CartItems } from "@hooks/useCart";

import { SubscriptionType } from "@helpers/products/getProduct";

interface GetUpsellProduct {
  (products?: CartItems[]): {
    productSlug?: string;
    packageSize: number;
    subscriptionType: SubscriptionType;
  };
}

const getUpsellProduct: GetUpsellProduct = (products) => {
  return {
    productSlug: products?.[0]?.product?.slug,
    packageSize: 2,
    subscriptionType: "MONTHLY",
  };
};

export default getUpsellProduct;
