import React, { FC, useState } from "react";

import Slider from "@components/common/Slider";

import reviewsCountData from "@configs/reviewsCountData";

import { Review } from "scripts/generateReviewsJson";
import { PurchasableProductName } from "scripts/generateProductsJson";
import MiniReviewSlide from "./slide/MiniReviewSlide";

type Props = {
  reviewData?: Review[];
  productName?: PurchasableProductName;
};

const MiniReview: FC<Props> = ({ reviewData, productName }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  return (
    <div className="mini-review-container">
      <div className="mini-review-header">
        <p className="description muted">{productName && reviewsCountData[productName]} reviews</p>
      </div>

      <div className="mini-review-body">
        <Slider
          slides={reviewData}
          setCurrentSlideIndex={setCurrentSlideIndex}
          currentSlideIndex={currentSlideIndex}
        >
          <MiniReviewSlide slide={reviewData?.[currentSlideIndex]} />
        </Slider>
      </div>
    </div>
  );
};

export default MiniReview;
