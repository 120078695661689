import React from "react";
import classNames from "classnames";

import Image1 from "@images/rating/1.jpg";
import Image2 from "@images/rating/2.jpg";
import Image3 from "@images/rating/3.jpg";
import Image4 from "@images/rating/4.jpg";
import Image5 from "@images/rating/5.jpg";

import Icon from "../Icon";

interface BriefRatingProps {
  className?: string;
}

const BriefRating = ({ className }: BriefRatingProps) => {
  return (
    <div className={classNames("brief-rating", className)}>
      <div className="brief-rating__images">
        {[Image1, Image2, Image3, Image4, Image5].map((Image, index) => (
          <img key={index} className="brief-rating__img" src={Image} alt={`rating-${index}`} />
        ))}
      </div>

      <div className="brief-rating__content">
        <div className="brief-rating__stars">
          <Icon name="star" />
          <Icon name="star" />
          <Icon name="star" />
          <Icon name="star" />
          <Icon name="star" />
        </div>

        <p className="brief-rating__text">
          Rated <b>4.9 out of 5</b>
        </p>
      </div>
    </div>
  );
};

export default BriefRating;
