import React, { FC, HTMLAttributes, ReactElement } from "react";

import classnames from "classnames";
import Icon, { IconName } from "../Icon";

interface SectionProps extends Omit<HTMLAttributes<HTMLElement>, "title"> {
  children: JSX.Element | JSX.Element[];
  className?: string;
  centered?: boolean;

  label?: string;
  title?: ReactElement | string;
  text?: ReactElement | string;

  icon?: IconName;
}

const Section: FC<SectionProps> = ({
  children,
  className,
  centered,
  label,
  title,
  text,
  icon,
  ...props
}) => {
  const showInfo = !!label || !!title || !!text;

  return (
    <section className={classnames("section", { centered }, className)} {...props}>
      {showInfo && (
        <div className="section__info">
          {label && <span className="section__info-label">{label}</span>}
          {title && <h2 className="section__info-title">{title}</h2>}
          {text && <p className="section__info-text">{text}</p>}
        </div>
      )}

      {icon && <Icon className="section__icon" name={icon} width={207} height={207} />}

      {children}
    </section>
  );
};

export default Section;
