/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import React, { FC, Fragment, useState } from "react";

import classNames from "classnames";

import Accordion from "@components/common/Accordion";
import Spinner from "@components/common/Spinner";
import TopDeals from "@components/checkout/TopDeals";

import CheckoutQualitiesList from "@components/checkout/CheckoutQualitiesList";

import { ShippingType } from "@components/checkout/CheckoutPaymentForm";
import type { CouponState, EstimateType } from "@pages/checkout";
import { PageProps } from "gatsby";
import { CartItem as ICartItem } from "@typings/cart";
import { CartItem } from "@components/widgets";
import { useSelector } from "react-redux";
import { selectCartItems } from "@store/cart";

import { isMedBoxProduct } from "@utils/product";
import CartNotifiers from "@components/widgets/cart/notifiers/CartNotifiers";
import { freeGiftItem, groovePlus } from "./CheckoutFormContainer";

interface Props {
  location: PageProps["location"];
  coupon: CouponState;
  order: boolean;
  loading: boolean;
  shipping: ShippingType;
  handleChangeCount: (id: string, count: number, type?: string) => void;
  handleChangeCoupon: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEstimate: (value: EstimateType) => Promise<void>;
  cartTotal: number;
  oldTotalCart: number;
  shippingPrice: string;
  formType: "information" | "payment";
}

export const separateProducts = ({
  cartItems,
}: {
  cartItems: ICartItem[];
}): {
  freeBeds: ICartItem[];
  paidBeds: ICartItem[];
  restProducts: ICartItem[];
  cartItems: ICartItem[];
} => {
  const beds = cartItems
    .filter((i) => i.product.slug === "anti-anxiety-calming-bed")
    .sort((a, b) => +a.product.price - +b.product.price);

  const totalBedsAmount = beds.reduce((acc, bed) => acc + bed.quantity, 0);

  let _freeBedsAmount = Math.floor(totalBedsAmount / 2);

  const fullPriceBeds: ICartItem[] = [];

  const freeBeds = beds.reduce<Array<ICartItem>>((acc, bed) => {
    const qty = bed.quantity;

    if (bed.product.price === 0) {
      acc.push(bed);

      return acc;
    }

    if (_freeBedsAmount) {
      if (_freeBedsAmount >= qty) {
        acc.push({
          ...bed,
          isFree: true,
          quantity: qty,
        });

        _freeBedsAmount -= qty;
      } else {
        const fullPriceAmount = qty - _freeBedsAmount;

        acc.push({
          ...bed,
          quantity: _freeBedsAmount,
          isFree: true,
        });

        _freeBedsAmount = 0;

        if (fullPriceAmount) {
          fullPriceBeds.push({
            ...bed,
            quantity: fullPriceAmount,
          });
        }
      }
    } else {
      fullPriceBeds.push({
        ...bed,
        quantity: qty,
      });
    }

    return acc;
  }, []);

  const restProducts = cartItems.filter((i) => i.product.slug !== "anti-anxiety-calming-bed");
  const calculatedCartItems = freeBeds.concat(fullPriceBeds).concat(restProducts);

  return {
    cartItems: calculatedCartItems,
    freeBeds,
    paidBeds: fullPriceBeds,
    restProducts,
  };
};

export const BedCheckoutItems: FC<{ cartItems: ICartItem[]; [key: string]: any }> = ({
  cartItems,
  order,
}) => {
  const { freeBeds, paidBeds } = separateProducts({ cartItems });
  const beltItemsInCart = cartItems.filter((item) => item.product.type === "SEATBELT");
  const medboxItemsInCart = cartItems.filter((item) => item.product.type === "MEDBOX");

  return (
    <React.Fragment>
      {paidBeds.map((cartItem, index) => (
        <CartItem
          key={`${cartItem.product.id}-${index}`}
          cartItem={cartItem}
          hideRemoveBtn={order}
          hideCounter={order}
        />
      ))}
      {freeBeds.map((cartItem, index) => (
        <CartItem
          key={`${cartItem.product.id}-${index}`}
          cartItem={cartItem}
          hideRemoveBtn={order}
          hideCounter={order}
        />
      ))}
      {(paidBeds.length > 0 || beltItemsInCart.length > 0) && medboxItemsInCart.length === 0 && (
        <CartItem
          hideRemoveBtn
          hideCounter
          cartItem={{
            isFree: true,
            quantity: 1,
            product: {
              name: groovePlus.product.name,
              image: groovePlus.product.image,
              price: groovePlus.product.price,
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

const CheckoutSummary: FC<Props> = ({
  location,
  coupon,
  order,
  loading,
  handleEstimate,
  handleChangeCoupon,
  cartTotal,
  shippingPrice,
  oldTotalCart,
  formType,
}) => {
  const isWizardFlow = location.search.includes("wizard");
  const [isActive, setIsActive] = useState<Record<string, boolean>>({ orderSummary: false });

  const cartItems = useSelector(selectCartItems);

  const isPayment = formType === "payment";

  const totalSavings = oldTotalCart - cartTotal;

  const isFreeGiftConditionSatisfied =
    process.env.GATSBY_ENABLE_FREE_GIFT &&
    cartItems.some(
      ({ product }) =>
        isMedBoxProduct(product) &&
        (product.subscriptionType === "EVERY_6_MONTHS" ||
          product.subscriptionType === "EVERY_12_MONTHS")
    );

  const freeGiftText = isFreeGiftConditionSatisfied ? "and received a FREE Dog Bed!" : "";

  const CheckoutSummaryContainer = (): JSX.Element => (
    <div className="checkout-summary-container">
      <div className="products">
        <React.Fragment>
          <CartNotifiers />

          {cartItems
            .filter((i) => i.product.slug !== "anti-anxiety-calming-bed")
            .map((cartItem, index) => (
              <CartItem
                key={`${cartItem.product.id}-${index}`}
                cartItem={cartItem}
                loading={loading}
                order={order}
                handleEstimate={handleEstimate}
              />
            ))}

          {isWizardFlow === false &&
            isFreeGiftConditionSatisfied &&
            cartItems.filter((i) => i.product.slug === "anti-anxiety-calming-bed")?.length ===
              0 && (
              <CartItem
                hideRemoveBtn
                cartItem={{
                  isFree: true,
                  quantity: 1,
                  product: {
                    name: freeGiftItem.product.name,
                    image: freeGiftItem.product.images.mainImage,
                    price: freeGiftItem.product.price,
                  },
                }}
              />
            )}

          {isWizardFlow &&
            cartItems
              .filter((i) => i.product.slug === "anti-anxiety-calming-bed")
              .map((cartItem, index) => (
                <CartItem
                  key={`${cartItem.product.id}-${index}`}
                  cartItem={cartItem}
                  loading={loading}
                  order={order}
                  isFreeItem
                  handleEstimate={handleEstimate}
                />
              ))}

          {isWizardFlow === false && (
            <BedCheckoutItems
              cartItems={cartItems}
              loading={loading}
              order={order}
              handleEstimate={handleEstimate}
            />
          )}
        </React.Fragment>
      </div>
      <div className="divider" />
      <div className="coupon-form-container">
        <div className="coupon-form">
          <input
            type="text"
            value={coupon.value}
            disabled={order}
            onChange={handleChangeCoupon}
            placeholder="Coupon code"
          />
          <button
            type="button"
            onClick={() => handleEstimate({ type: "APPLY_COUPON" })}
            disabled={loading || !coupon.value || order}
          >
            {loading ? <Spinner /> : "Apply"}
          </button>
        </div>
        <div
          className={classNames(
            "message",
            coupon.message.type === "SUCCESS" ? "success" : "error",
            { active: coupon.message.title }
          )}
        >
          {coupon.message.title}
        </div>
      </div>
      <div className="total-row shipping">
        {coupon.isApplied && (
          <div className="row-item discount-row">
            <span>Coupon:</span>
            <div className="discount">
              <span>-${coupon.discount}</span>
              <button
                type="button"
                disabled={order}
                onClick={() => handleEstimate({ type: "RESET" })}
              >
                [Remove]
              </button>
            </div>
          </div>
        )}
        <div className="row-item">
          <span>Shipping:</span>
          <span>{shippingPrice}</span>
        </div>
      </div>
      <div className="total-row">
        <div className="row-item">
          <span>Total:</span>
          <div>
            <span className="old">${oldTotalCart?.toFixed(2)}</span>
            <span>${cartTotal?.toFixed(2)}</span>
          </div>
        </div>
      </div>

      {+totalSavings > 0 && (
        <div className="total-savings-container">
          <p className="total-savings">
            Your discount has successfully been applied!
            <br />
            You’ve saved ${totalSavings.toFixed(2)} {freeGiftText}
          </p>
        </div>
      )}

      {isPayment || isWizardFlow ? (
        <CheckoutQualitiesList />
      ) : (
        <TopDeals loading={loading} order={order} handleEstimate={handleEstimate} />
      )}
    </div>
  );

  return (
    <Fragment>
      {CheckoutSummaryContainer()}
      <Accordion
        title="Order Summary"
        content={CheckoutSummaryContainer()}
        setIsActive={setIsActive}
        isActive={isActive}
        name="orderSummary"
        className="order-summary-accordion"
        cartTitle
        titleClassName="order-summary-accordion-title"
        cartTotal={cartTotal}
        oldTotalCart={oldTotalCart}
      />
    </Fragment>
  );
};

export default CheckoutSummary;
