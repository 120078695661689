/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from "react";
import { FormikErrors } from "formik";

import Icon from "@components/common/Icon";

import { formatTimeInMs } from "@utils/formatTimeInMs";
import {
  CheckoutFormValues,
  PaymentsErrorsValues,
} from "@components/checkout/CheckoutFormContainer";
import { FormFieldsName } from "@constants/common";

interface CheckoutPaymentBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  time: number;
  paymentErrors: PaymentsErrorsValues;
  errors: FormikErrors<CheckoutFormValues>;
  isShowErrors: boolean;
  checkoutError: string;
}

const CheckoutPaymentBtn = ({
  type = "button",
  text,
  time,
  errors,
  paymentErrors,
  isShowErrors,
  checkoutError,
  ...props
}: CheckoutPaymentBtnProps) => {
  const { hours, minutes, seconds } = formatTimeInMs(time);

  const errorFieldsSet = new Set<string>();

  [...Object.keys(errors), ...Object.keys(paymentErrors)].forEach((field) => {
    const isPaymentError = paymentErrors[field as keyof typeof paymentErrors];
    const isError = errors[field as keyof typeof errors];

    if (isPaymentError || (isError && !isPaymentError)) {
      errorFieldsSet.add(FormFieldsName[field as keyof typeof FormFieldsName] || field);
    }
  });

  const errorFields = Array.from(errorFieldsSet);

  const errorMessage =
    errorFields.length > 0 && isShowErrors
      ? `Please check ${errorFields.join(", ")} to ensure it is correct before proceeding`
      : checkoutError.length
      ? checkoutError
      : "";

  return (
    <React.Fragment>
      {errorMessage && <div className="error-container">{errorMessage}</div>}
      <button className="checkout-payment-btn" type={type} {...props}>
        <div className="checkout-payment-btn__decoration">
          <span className="checkout-payment-btn__text">{text}</span>
          {/* <span className="checkout-payment-btn__timer">
          {hours}:{minutes}:{seconds}
        </span> */}
        </div>

        <div className="checkout-payment-btn__icon">
          <Icon name="sm-arrow-right" />
        </div>
      </button>
    </React.Fragment>
  );
};

export default CheckoutPaymentBtn;
