import React, { Dispatch, FC, SetStateAction } from "react";

import Button from "@components/common/Button";

import { Review } from "@helpers/getReviews";

type Props = {
  slides?: Review[];
  children: JSX.Element;
  setCurrentSlideIndex: Dispatch<SetStateAction<number>>;
  currentSlideIndex: number;
};

const Slider: FC<Props> = ({ slides, children, currentSlideIndex, setCurrentSlideIndex }) => {
  const handleNextSlide = (): void => {
    if (slides && currentSlideIndex < slides.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    } else {
      setCurrentSlideIndex(0);
    }
  };

  const handlePrevSlide = (): void => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    } else if (slides) {
      setCurrentSlideIndex(slides.length - 1);
    }
  };

  return (
    <div className="slider">
      <Button
        onClick={handlePrevSlide}
        color="link-secondary"
        icon="arrow-left"
        className="prev-slide"
        aria-label="prev-slide"
      />

      {children}

      <Button
        onClick={handleNextSlide}
        color="link-secondary"
        icon="arrow-left"
        className="next-slide"
        iconClassName="arrow-right"
        aria-label="next-slide"
      />
    </div>
  );
};

export default Slider;
