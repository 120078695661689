import React from "react";
import { Button } from "./common";
import Icon from "./common/Icon";

interface OverlayProps {
  onEnter: () => void;
}

const Overlay: React.FC<OverlayProps> = ({ onEnter }) => {
  return (
    <div className="overlay-age">
      <div className="overlay-age-logo">
        <Icon name="logo" width={180} height={37} />
      </div>
      <div className="overlay-age-content">
        <h1>Welcome! You must be 21+ to enter this site.</h1>
        <Button onClick={onEnter} type="button" icon="arrow-right">
          Enter
        </Button>
      </div>
    </div>
  );
};

export default Overlay;
