import { CartItem } from "@typings/cart";

import { BASE_URL } from "@constants/common";

import { KlaviyoCartItem } from "../types/KlaviyoCartItem";

export const transformCartItemsToKlaviyo = (cartItems: CartItem[]): KlaviyoCartItem[] => {
  return cartItems.map((cartItem) => {
    const { quantity, product, isFree } = cartItem;

    if (!product.image) {
      console.warn("Has no image: ", product);
    }

    const productURL = `${BASE_URL}/shop/${product.slug}`;
    const productImageURL = `${BASE_URL}${product.image}`;

    const productPrice = isFree ? 0 : product.price;

    return {
      ProductID: product.id,
      VariantID: product.id,
      Color: product.color || "",
      SKU: product.sku,
      Name: product.name,
      Quantity: quantity,
      PackageSize: product.packageSize || 1,
      Price: productPrice,
      LineTotal: productPrice * quantity,
      URL: productURL,
      MainImageURL: productImageURL,
      ImageURL: productImageURL,
      Categories: ["Best Sellers", "Health &amp; Wellness"],
    };
  });
};
