import { scroller } from "react-scroll";

const scrollTo = (name: string) => {
  scroller.scrollTo(name, {
    duration: 600,
    delay: 0,
    smooth: "easeInOutQuart",
    ignoreCancelEvents: true,
    isDynamic: true,
    offset: 0,
  });
};

export default scrollTo;
