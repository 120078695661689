import React, { FC } from "react";

import checkoutQualities from "@configs/checkoutQualities";

const CheckoutQualitiesList: FC = () => {
  return (
    <div className="qualities-list">
      <h1 className="qualities-list-title">Why over 1,000,000+ customers love Groove</h1>
      <div className="qualities">
        {checkoutQualities.map(({ title, description, icon }) => (
          <div className="quality-item" key={title}>
            {icon}
            <div className="quality-description">
              <span className="title">{title}</span>
              <span className="description">{description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckoutQualitiesList;
