import React, {
  createContext,
  Dispatch,
  FC,
  memo,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";

interface WizardContextProps {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  prevStep: () => void;
  nextStep: () => void;
}

export const WizardContext = createContext<WizardContextProps>({
  currentStep: 1,
  setCurrentStep: () => ({}),
  prevStep: () => ({}),
  nextStep: () => ({}),
});

export const AMOUNT_OF_SCREENS = 5;

export const useWizard = () => useContext(WizardContext);

const WizardProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const prevStep = useCallback(() => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
  }, []);

  const nextStep = useCallback(() => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  }, []);

  return (
    <WizardContext.Provider value={{ currentStep, setCurrentStep, prevStep, nextStep }}>
      {children}
    </WizardContext.Provider>
  );
};

export default memo<FC<PropsWithChildren>>(WizardProvider);
