import React from "react";

import { CartItem } from "@typings/cart";
import { Information } from "scripts/generateInformationJson";

import CartItemTotal from "./total/CartItemTotal";
import CartItemName from "./name/CartItemName";

interface CartItemHeaderProps {
  cartItem: CartItem;
  information: Information;
  redirectToProductPage: () => void;
}

const CartItemHeader = ({ cartItem, information, redirectToProductPage }: CartItemHeaderProps) => {
  return (
    <div className="cart-item__header">
      <div className="cart-item__title">
        <CartItemName
          cartItem={cartItem}
          information={information}
          redirectToProductPage={redirectToProductPage}
        />
        <CartItemTotal cartItem={cartItem} />
      </div>
    </div>
  );
};

export default CartItemHeader;
