import React from "react";

import Icon from "@components/common/Icon";

const checkoutQualities = [
  {
    title: "Easy Returns & Refunds",
    description: "30 Day Return Policy, And Easy Refunds!",
    icon: <Icon name="guarantee" />,
  },
  {
    title: "Fast Free Shipping",
    description: "All Orders Are Shipped From Our U.S.A Warehouse",
    icon: <Icon name="fast-ship" />,
  },
  {
    title: "High Quality Ingredients",
    description:
      "All Products Are Made And Shipped In The USA Using The Highest Quality Ingredients",
    icon: <Icon name="quality-assured" />,
  },
  {
    title: "Excellent Customer Service",
    description: "Email Us At: support@officialgroove.com We Are Available 24/7",
    icon: <Icon name="customer-service" />,
  },
];

export default checkoutQualities;
