import React, { FC, useState } from "react";

import classnames from "classnames";

import Accordion from "@components/common/Accordion";
import Section from "@components/common/section/Section";

import faqSectionData from "@configs/faqSectionData";

import faqImage from "@images/overlaybg.jpg";

interface Faq {
  location?: Location;
  isProductFaq?: boolean;
  hidden?: string[];
  className?: string;
}

const initialValue = faqSectionData.reduce((accumulator, currentValue) => {
  return { ...accumulator, [currentValue.name]: false };
}, {});

const Faq: FC<Faq> = ({ location, isProductFaq, hidden, className }) => {
  const [isActive, setIsActive] = useState<Record<string, boolean>>(initialValue);

  const elementName = location?.hash.substring(1);

  return (
    <Section className={classnames("faq", { "product-faq": isProductFaq }, className)} centered>
      <div className="faq-wrapper">
        <div className="faq-section">
          <h2 className="section-title-text">Frequently Asked Questions</h2>

          <div className="faq-container">
            {faqSectionData
              ?.filter(({ name }) => !hidden?.includes(name))
              .map(({ title, content, name }, index) => (
                <Accordion
                  key={index}
                  title={title}
                  content={content}
                  name={name}
                  elementName={elementName}
                  isActive={isActive}
                  setIsActive={setIsActive}
                />
              ))}
          </div>
        </div>

        {isProductFaq && (
          <div className="photo-container">
            <img src={faqImage} alt="flowers" loading="lazy" />
          </div>
        )}
      </div>
    </Section>
  );
};

export default Faq;
