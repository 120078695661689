import React, { FC, useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player/lazy";

import useProduct from "@hooks/useProduct";

import Section from "@components/common/section/Section";
import Spinner from "@components/common/Spinner";

import QuotationMarkIcon from "@assets/svg/common/quotation-mark.svg";

export type RealResultsProps = { video: any; description: string; author: string } | null;

const RealResults: FC = () => {
  const playerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [play, setPlay] = useState(false);
  const [onLoading, setLoading] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShow(true);
          setPlay(true);
        } else {
          setPlay(false);
        }
      },
      { threshold: 0.01 }
    );

    if (playerRef.current) {
      observer.observe(playerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(playerRef?.current);
      }
    };
  }, []);

  const {
    information: { realResults },
  } = useProduct();

  if (!realResults) {
    return null;
  }

  const handleProgress = ({ loadedSeconds }: { loadedSeconds: number }): void => {
    if (loadedSeconds > 0.01) {
      setLoading(false);
    }
  };

  return (
    <Section title="Real Results" className="real-results" centered>
      <div className="real-results-wrapper">
        <div className="section-description">
          <QuotationMarkIcon />
          <span className="description">{realResults.description}</span>
          <QuotationMarkIcon />
        </div>

        <span className="author">— {realResults.author}</span>

        <div className="video-container" ref={playerRef}>
          {onLoading && <Spinner />}

          {show && (
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100%"
              url={realResults.video}
              playing={play}
              muted
              controls
              playsinline
              loop
              onProgress={handleProgress}
            />
          )}
        </div>
      </div>
    </Section>
  );
};

export default RealResults;
