import React, { FC, Fragment, PropsWithChildren, useEffect, useState } from "react";

import { PageProps, Script, navigate } from "gatsby";

import Footer from "@layouts/Footer";
import Header from "@layouts/Header";
import ModalProvider from "@providers/ModalProvider";

import { Cart } from "@components/widgets";
import Modal from "@components/modal";
import useLocalStorageState from "use-local-storage-state";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { getItemsFromLocalStorage } from "@store/cart";
import WizardProvider from "@providers/WizardProvider";
import Overlay from "@components/Overlay";

type Props = PropsWithChildren & PageProps;

const Layout: FC<Props> = ({ children, location }) => {
  const url = location.href && new URL(location.href);
  const params = url && new URLSearchParams(url.search);

  const gclid = params && params.get("gclid");

  const [_, setGCLID] = useLocalStorageState<string>("mp-gclid");
  const [isShowOverlay, setIsShowOverlay] = useLocalStorageState<boolean>("isShowAgeOverlay", {
    defaultValue: true,
  });
  const [hasWindow, setHasWindow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setHasWindow(true);
  }, []);

  useEffect(() => {
    dispatch(getItemsFromLocalStorage());

    if (gclid) {
      setGCLID(gclid);
    }
  });

  if (!hasWindow) return null;

  if (isShowOverlay && hasWindow) return <Overlay onEnter={() => setIsShowOverlay(false)} />;

  if (location.pathname.includes("/wizard")) {
    return (
      <WizardProvider>
        {process.env.GATSBY_REVCENT_TRACK_SCRIPT && (
          <Script src={process.env.GATSBY_REVCENT_TRACK_SCRIPT} />
        )}

        <main className="wizard-page-container">{children}</main>
      </WizardProvider>
    );
  }

  if (location.pathname.includes("/checkout")) {
    return (
      <ModalProvider>
        <Modal />
        <main className="checkout-page-container">{children}</main>
      </ModalProvider>
    );
  }

  if (location.pathname.includes("/thankyou")) {
    return (
      <ModalProvider>
        <Modal />
        <main className="checkout-page-container">{children}</main>
      </ModalProvider>
    );
  }

  const locationName = location.pathname.slice(
    location.pathname.lastIndexOf("/", location.pathname.length - 2) + 1,
    location.pathname.length - 1
  );

  return (
    <div className={classNames("root", `${locationName}-page`)}>
      <ModalProvider>
        <Header location={location} />
        <Cart />
        <Modal />

        {process.env.GATSBY_REVCENT_TRACK_SCRIPT && (
          <Script src={process.env.GATSBY_REVCENT_TRACK_SCRIPT} />
        )}

        <main>{children}</main>

        <Footer />
      </ModalProvider>
    </div>
  );
};

export default Layout;
