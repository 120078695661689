import { ProductName } from "@helpers/products/getProduct";

const reviewsCountData: Record<ProductName, number> = {
  "Delta-8 THC Gummies": 1467,
  Cannabis: 3526,
  Vape: 4818,
  "Delta-8 TCC Gummies": 1213,
};

export default reviewsCountData;
