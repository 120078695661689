import React, { FC } from "react";

import StarsRating from "@components/common/StarsRating";
import { Review } from "scripts/generateReviewsJson";

type Props = {
  slide?: Review;
};

const MiniReviewSlide: FC<Props> = ({ slide }) => {
  return (
    <div className="slide">
      <div className="rating">
        <StarsRating currentRating={5} disabled />
      </div>

      <div className="review">
        <span className="text">“{slide?.body}”</span>
      </div>

      <div className="author">
        <span className="text muted">— {slide?.name}</span>
      </div>
    </div>
  );
};

export default MiniReviewSlide;
