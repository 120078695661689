/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, FC, SetStateAction } from "react";

import classNames from "classnames";

import RecommendIcon from "@assets/svg/common/recommend.svg";

import { Product } from "scripts/generateProductsJson";
import getInformation from "@helpers/information/getInformation";

interface Props {
  fbwProduct: Product;
  isProductSelected: boolean;
  setIsProductSelected: Dispatch<SetStateAction<boolean>>;
  paragraph?: number;
}

const FrequentlyBrought: FC<Props> = ({
  fbwProduct,
  isProductSelected,
  setIsProductSelected,
  paragraph = 4,
}) => {
  const { name, price, oldPrice = 0 } = fbwProduct;
  const { images } = getInformation({ name: fbwProduct.name });

  const handleSelectProduct = (): void => {
    setIsProductSelected((prev) => !prev);
  };

  return (
    <div className="frequently-brought-section">
      <span className="selector-label">{paragraph}. Frequently bought with:</span>
      <div
        className={classNames("product-item-container", {
          active: isProductSelected,
        })}
        onClick={handleSelectProduct}
      >
        <div className="recommend-icon-container">
          <RecommendIcon className="recommend-icon" />
        </div>
        <div className="product-item">
          <div className="product-image">
            <img src={images.main} width={62} height={62} alt="" />
          </div>
          <div className="product-description">
            <span className="product-name">{name}</span>
            <span className="discount">(25% off)</span>
            <div className="prices">
              <span className="price">${price}</span>
              {!!oldPrice && <span className="old-price">${oldPrice}</span>}
            </div>
          </div>
        </div>
        <div className="circle">
          <div className="sub-circle" />
        </div>
      </div>
    </div>
  );
};

export default FrequentlyBrought;
