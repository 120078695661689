import React from "react";

const ExpiredPopup = () => {
  return (
    <div className="invalid-popup-expired">
      <p style={{ color: "red" }}>Please enter a valid expiration date.</p>
      <div className="invalid-popup-triangle" />
    </div>
  );
};

export default ExpiredPopup;
