import React, { FC, useMemo } from "react";

import { EstimateType } from "@pages/checkout";

import getUpsellProducts from "@helpers/getUpsellProducts";
import { CartItem } from "@components/widgets";
import { useSelector } from "react-redux";
import { selectCartItems } from "@store/cart";

type Props = {
  loading: boolean;
  order: boolean;
  handleEstimate: (value: EstimateType) => Promise<void>;
};

const TopDeals: FC<Props> = ({ loading, order }) => {
  const cartItems = useSelector(selectCartItems);

  const upsellProducts = useMemo(() => getUpsellProducts(cartItems), [cartItems]);

  if (upsellProducts.length === 0) {
    return null;
  }

  return (
    <div className="top-deals">
      <h2 className="title">Today’s Top Deals</h2>
      <p className="description">One time add-ons tailored for you!</p>
      <div className="products">
        {upsellProducts.map((product, index) => (
          <CartItem
            key={index}
            cartItem={{ product, quantity: 1 }}
            type="ADD"
            loading={loading}
            order={order}
            isTopDeal
          />
        ))}
      </div>
    </div>
  );
};

export default TopDeals;
