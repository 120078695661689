/* eslint-disable react/no-unescaped-entities */
import React from "react";

import PaymentMethodsGallery from "@components/common/image/gallery/payment-methods/PaymentMethodsGallery";
import SecurityCertificationsGallery from "@components/common/image/gallery/security-certifications/SecurityCertificationsGallery";

import CheckoutGuaranteesItem from "./item/CheckoutGuaranteesItem";

const CheckoutGuarantees = () => {
  return (
    <div className="checkout-guarantees">
      <CheckoutGuaranteesItem icon="shield-check-mark" title="Safe Payment Options">
        <PaymentMethodsGallery />
      </CheckoutGuaranteesItem>

      <CheckoutGuaranteesItem icon="shield-check-mark" title="Security certifications">
        <SecurityCertificationsGallery />
      </CheckoutGuaranteesItem>

      <CheckoutGuaranteesItem icon="shield-check-mark" title="GROOVE payment protection">
        Shop with peace of mind on GROOVE, knowing that if anything goes amiss, we're here to
        support you every step of the way.
      </CheckoutGuaranteesItem>

      <CheckoutGuaranteesItem icon="lock" title="Secure privacy">
        Your privacy matters! Rest assured, we keep your info secure. No selling, just using it to
        enhance our services per our privacy policy.
      </CheckoutGuaranteesItem>
    </div>
  );
};

export default CheckoutGuarantees;
