import type { CartItem } from "@typings/cart";

import { areCartItemsEqual } from "../common/areCartItemsEqual";

export const removeCartItems = (cartItems: CartItem[], removedCartItems: CartItem[]) => {
  const updatedCartItems = cartItems
    .map((item) => {
      const removedItem = removedCartItems.find((ri) => areCartItemsEqual(ri, item));

      if (!removedItem) return item;

      const newQuantity = item.quantity - removedItem.quantity;

      if (newQuantity <= 0) return null;

      return {
        ...item,
        quantity: newQuantity,
      };
    })
    .filter(Boolean);

  const hasMainProduct = updatedCartItems.some((item) => item?.product.type === "EDIBLE");
  const hasSubscriptionProduct = updatedCartItems.some(
    (item) => item?.product.subscriptionType !== "NONE" && !item?.isFree
  );

  if (!hasMainProduct || !hasSubscriptionProduct) {
    return updatedCartItems.filter((item) => !item?.isFree);
  }

  return updatedCartItems;
};
