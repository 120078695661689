import React, { FC } from "react";

import Icon from "@components/common/Icon";

import { DignitiesItem } from "@constants/product/dignities";

interface DignitiesProps {
  dignities: DignitiesItem[];
}

const Dignities: FC<DignitiesProps> = ({ dignities }) => {
  return (
    <div className="customer-benefits-section">
      <div className="list-items">
        {dignities.map((dignity, index) => (
          <div key={`${dignity.strong}-${index}`} className="list-item">
            <Icon name={dignity.icon} className={`${dignity.icon}-icon`} />
            <span className="benefits-test">
              <strong>{dignity.strong}</strong> {dignity.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dignities;
