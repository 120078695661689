import React, { FC } from "react";

import useProduct from "@hooks/useProduct";

import Section from "@components/common/section/Section";

const Ingredients: FC = () => {
  const { product: { imageIngredients } = {} } = useProduct();

  return (
    <Section className="product-section main-ingredients" centered>
      <div className="ingredients-container">
        {imageIngredients?.map((Name, index) => {
          return <Name key={index} />;
        })}
      </div>
    </Section>
  );
};

export default Ingredients;
