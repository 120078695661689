import React, { useMemo } from "react";

import { CartItem } from "@typings/cart";

import { Information } from "scripts/generateInformationJson";

import { QUANTITY_MAP } from "@components/pages/product/purchase/common/selectors/ProductSelectors";

interface CartItemNameProps {
  cartItem: CartItem;
  information: Information;
  redirectToProductPage: () => void;
}

const CartItemName = ({ cartItem, information, redirectToProductPage }: CartItemNameProps) => {
  const { product } = cartItem;

  const packageSize = useMemo(() => {
    if (product.packageSize) {
      return QUANTITY_MAP[product.type]?.[product.name]?.[product.packageSize - 1].title;
    }

    return null;
  }, [product]);

  return (
    <button className="cart-item__name" type="button" onClick={redirectToProductPage}>
      {information.name} {packageSize && `(${packageSize})`}
    </button>
  );
};

export default CartItemName;
