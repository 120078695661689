import React, { FC, useState } from "react";

import classNames from "classnames";

import Section from "@components/common/section/Section";
import Accordion from "@components/common/Accordion";

import { BenefitsItem } from "scripts/generateInformationJson";
import Icon from "@components/common/Icon";

type Props = {
  benefits?: BenefitsItem[];
  name?: string;
  image?: string;
  className?: string;
};

const MobileBenefits: FC<Props> = ({ benefits, name, image, className = "image" }) => {
  const [isActive, setIsActive] = useState<Record<string, boolean>>({ "": false });

  return (
    <Section className="benefits-mobile" centered>
      <h2 className="section-title-text">{`${name} Benefits`}</h2>

      <div className="benefits-column">
        {benefits?.map(({ title, text, icon }, index) => (
          <Accordion
            key={index}
            content={text}
            name={title}
            isActive={isActive}
            setIsActive={setIsActive}
            title={
              <React.Fragment>
                <Icon width={32} height={32} name={icon} />
                {title}
              </React.Fragment>
            }
          />
        ))}
      </div>

      <div className={classNames("benefits-column", className)}>
        <img alt={name} src={image} width={375} height={375} />
      </div>
    </Section>
  );
};

export default MobileBenefits;
