import React, { Dispatch, FC, SetStateAction, useEffect } from "react";

import { useFormikContext } from "formik";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import * as yup from "yup";

import PlacesAutocomplete from "@components/common/PlacesAutocomplete";
import InputField from "@components/common/InputField";
import CheckboxField from "@components/common/CheckboxField";
import PhoneInput from "@components/common/PhoneInput";
import SelectField from "@components/common/SelectField";

import stateOptions from "@helpers/data/stateOptions";

import type { CheckoutFormValues } from "@components/checkout/CheckoutFormContainer";
import { CartItem } from "@typings/cart";
import { trackStartedCheckout } from "@analytics/klaviyo";

interface Props {
  formType: "information" | "payment";
  focus?: string;
  setFocus: Dispatch<SetStateAction<string | undefined>>;
  orderId: string;
  cartItems: CartItem[];
  isShowErrors: boolean;
}

const CheckoutInformationForm: FC<Props> = ({
  formType,
  focus,
  setFocus,
  orderId,
  cartItems,
  isShowErrors,
}) => {
  const { values, setValues, setFieldValue, errors } = useFormikContext<CheckoutFormValues>();
  const isAutoCompleteDisable = process.env.GATSBY_GOOGLE_AUTOCOMPLETE_DISABLE === "true";

  useEffect(() => {
    return () => {
      setFocus(undefined);
    };
  }, [setFocus]);

  const handleChange = (data: any, type?: string): void => {
    if (typeof data === "string") {
      return setFieldValue(type === "shipping" ? "streetAddress" : "diffStreetAddress", data);
    }

    const label = data?.structured_formatting?.main_text;

    setFieldValue(type === "shipping" ? "streetAddress" : "diffStreetAddress", label);

    void geocodeByPlaceId(data.place_id).then((res) => {
      const addressComponents = res?.[0]?.address_components || [];

      const postalCode = addressComponents.find((c) => c.types.includes("postal_code"))?.short_name;
      const state = addressComponents.find((c) =>
        c.types.includes("administrative_area_level_1")
      )?.short_name;
      const city = addressComponents.find(
        (c) => c.types.includes("locality") && c.types.includes("political")
      )?.long_name;

      setValues({
        ...values,
        [type === "shipping" ? "streetAddress" : "diffStreetAddress"]: label,
        [type === "shipping" ? "zipCode" : "diffZipCode"]: postalCode || "",
        [type === "shipping" ? "state" : "diffState"]: state || "",
        [type === "shipping" ? "city" : "diffCity"]: city || "",
      });
    });
  };

  const handleEmailOnBlur = (): void => {
    const { email } = values;

    yup
      .string()
      .email("Invalid email")
      .required()
      .validate(email)
      .then(() => {
        trackStartedCheckout({
          email,
          orderId,
          cartItems,
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  // if (formType !== "information") return null;

  return (
    <div className="form-content information-form">
      <span className="description">Contact Information</span>

      <div className="row">
        <div className="row-with-error">
          <InputField
            label="Email"
            name="email"
            type="email"
            autoFocus={focus === "email"}
            onBlur={handleEmailOnBlur}
            className={errors.email && isShowErrors ? "error-input" : ""}
          />
          {errors.email && isShowErrors && <span className="error-message">{errors.email}</span>}
        </div>
        <div>
          <PhoneInput
            autoFocus={focus === "phoneNumber"}
            // className="phone-number"
            inputClassname={errors.phoneNumber && isShowErrors ? "error-input" : ""}
          />
          {errors.phoneNumber && isShowErrors && (
            <span className="error-message">{errors.phoneNumber}</span>
          )}
        </div>
      </div>

      <span className="description">Delivery</span>

      <div className="row">
        <div className="row-with-error">
          <InputField
            label="First name"
            name="firstName"
            autoFocus={focus === "firstName"}
            className={errors.firstName && isShowErrors ? "error-input" : ""}
          />
          {errors.firstName && isShowErrors && (
            <span className="error-message">{errors.firstName}</span>
          )}
        </div>
        <div className="row-with-error">
          <InputField
            label="Last name"
            name="lastName"
            className={errors.lastName && isShowErrors ? "error-input" : ""}
          />
          {errors.lastName && isShowErrors && (
            <span className="error-message">{errors.lastName}</span>
          )}
        </div>
      </div>

      <div className="row">
        <div className="row-with-error">
          {isAutoCompleteDisable ? (
            <InputField
              label="Street Address"
              name="streetAddress"
              className={errors.streetAddress && isShowErrors ? "error-input" : ""}
            />
          ) : (
            <PlacesAutocomplete
              value={values.streetAddress}
              onChange={(data) => handleChange(data, "shipping")}
              label="Street Address"
            />
          )}
          {errors.streetAddress && isShowErrors && (
            <span className="error-message">{errors.streetAddress}</span>
          )}
        </div>
        <div className="row-with-error">
          <SelectField
            placeholder="State"
            name="state"
            className={errors.state && isShowErrors ? "react-select-error" : ""}
            options={stateOptions}
            animatedPlaceholder
          />
          {errors.state && isShowErrors && <span className="error-message">{errors.state}</span>}
        </div>
      </div>

      <div className="row">
        <div className="row-with-error">
          <InputField
            autoComplete="street-address2"
            label="Apartment, suite, building (optional)"
            name="streetAddress2"
            className={errors.streetAddress2 && isShowErrors ? "error-input" : ""}
          />
          {errors.streetAddress2 && isShowErrors && (
            <span className="error-message">{errors.streetAddress2}</span>
          )}
        </div>
      </div>

      <div className="row">
        <div className="row-with-error">
          <InputField
            label="Town / City"
            name="city"
            autoFocus={focus === "city"}
            className={errors.city && isShowErrors ? "error-input" : ""}
          />
          {errors.city && isShowErrors && <span className="error-message">{errors.city}</span>}
        </div>
        <InputField label="Country" name="country" disabled />
        <div className="row-with-error">
          <InputField
            label="Zip Code"
            name="zipCode"
            className={errors.zipCode && isShowErrors ? "error-input" : ""}
          />
          {errors.zipCode && isShowErrors && (
            <span className="error-message">{errors.zipCode}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutInformationForm;
