/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import reviews from "@data/reviews.json";

import { PageProps, navigate } from "gatsby";
import { v4 as uuidv4 } from "uuid";

import * as yup from "yup";

import { Form, FormikProvider, useFormik } from "formik";

import useLocalStorageState from "use-local-storage-state";
import axios from "axios";

import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";

import CheckoutInformationForm from "@components/checkout/CheckoutInformationForm";
import FormTypeBreadcrumb from "@components/checkout/FormTypeBreadcrumb";
import CheckoutPaymentForm, { ShippingType } from "@components/checkout/CheckoutPaymentForm";
import FormErrors from "@components/checkout/FormErrors";
import { isMedBoxProduct } from "@utils/product";
import type { CheckoutFormType, CouponState } from "@pages/checkout";

import scrollTo from "@helpers/scrollTo";
import { Product } from "@helpers/products/getProduct";

import { useSelector } from "react-redux";
import { selectCartItems } from "@store/cart";
import { track, trackPlacedOrder } from "@analytics/klaviyo";
import { gaAddPaymentInfo, gaAddShippingInfo, gaPurchase } from "@analytics/google";
import { CartItem } from "@typings/cart";

import Timer from "@components/common/timer/Timer";

import CheckoutLeave from "@components/modal/checkout/leave/CheckoutLeave";
import { isValidFirstDigit } from "@helpers/checkout/isValidFirstDigit";
import Icon from "@components/common/Icon";
import { MiniReview } from "@components/sections/product";
import CheckoutPaymentBtn from "./buttons/payment/CheckoutPaymentBtn";
import validationSchema from "./validationSchema";
import CheckoutGuarantees from "./guarantees/CheckoutGuarantees";

export const freeGiftItem: CartItem = {
  isFree: true,
  quantity: 1,
  product: {
    deliveryFrequency: "NONE",
    id: "pg1qB1vq8gIY5KY5dZAV",
    quantity: 1,
    price: 0,
    oldPrice: 0,
    secondName: "",
    images: {
      mainImage: "/images/dog-bed.png",
      mainProductImage: "",
      additionalImages: [],
      quantityImages: [],
      ingredients: [],
      benefitsImage: "",
      feedingGuide: "",
    },
    description:
      "You will receive an email after purchase to confirm the desired size of your dog bed (S - XL)",
    qualities: [],
    imageIngredients: [],
    frequentlyBrought: "",
    faq: [],
    fullIngredients: [],
    realResults: null,
    color: "",
    keyIngredients: [],
    slug: "premium-cuddler-bed",
    name: "Anti-Anxiety Calming Bed",
    sku: "free-cuddler-bed",
  },
};

export const groovePlus: CartItem = {
  isFree: true,
  quantity: 1,
  product: {
    deliveryFrequency: "NONE",
    id: "8ragLjlOzgF2m4adGAJ1",
    quantity: 1,
    price: 0,
    oldPrice: 0,
    secondName: "",
    type: "GROOVEPLUS",
    image: "/images/groove-plus.png",
    images: {
      mainImage: "/images/groove-plus.png",
      mainProductImage: "",
      additionalImages: [],
      quantityImages: [],
      ingredients: [],
      benefitsImage: "",
      feedingGuide: "",
    },
    description: "",
    qualities: [],
    imageIngredients: [],
    frequentlyBrought: "",
    faq: [],
    fullIngredients: [],
    realResults: null,
    color: "",
    keyIngredients: [],
    slug: "groove-plus",
    name: "Groove Plus",
    sku: "grooveplus",
  },
};

export interface CheckoutFormValues {
  email: string;
  firstName: string;
  lastName: string;
  streetAddress?: string;
  streetAddress2?: string;
  city: string;
  zipCode: string;
  state: string;
  phoneNumber?: string;
  country: string;
  isSameAddress: boolean;
  diffStreetAddress?: string;
  diffStreetAddress2?: string;
  diffCity: string;
  diffZipCode: string;
  diffState: string;
  diffCountry: string;
  cardNumber: string;
  cardExpiry: string;
  cardCode: string;
}

interface Props {
  location: PageProps<object, object, any>["location"];
  orderId: string;
  coupon: CouponState;
  setCoupon: Dispatch<SetStateAction<CouponState>>;
  shipping: ShippingType;
  order: boolean;
  setOrder: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
  handleChangeShipping: (value: any) => void;
  orderLoading: boolean;
  setOrderLoading: Dispatch<SetStateAction<boolean>>;
  formType: CheckoutFormType;
  setFormType: (newFormType: CheckoutFormType) => void;
  cartTotal: number;
  cartSubTotal: number;
  shippingPrice: string;
  oldTotalCart: number;
  time: number;
  setTime: Dispatch<SetStateAction<number>>;
}

type OrderValues = {
  orderId: string;
  shippingAddress: {
    addressLine1: string;
    city: string;
    zip: string;
    country: string;
    state: string;
  };
  products: Product[];
  customer: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    billingAddress: {
      addressLine1: string;
      city: string;
      country: string;
      state: string;
      zip: string;
    };
  };
  creditCard: {
    cardNumber: string;
    cardCode: string;
    expMonth: number;
    expYear: number;
  };
  shippingMethod: string;
  coupon: string;
};

export type PaymentsErrorsValues = {
  expiryError: string | null;
  cardError: string | null;
  cvvError: string | null;
};

const CheckoutFormContainer: FC<Props> = ({
  location,
  orderId,
  formType,
  setFormType,
  coupon,
  orderLoading,
  order,
  setOrder,
  setEmail,
  shipping,
  setCoupon,
  handleChangeShipping,
  setOrderLoading,
  cartTotal,
  cartSubTotal,
  shippingPrice,
  oldTotalCart,
  time,
  setTime,
}) => {
  const [orderValues, setOrderValues] = useState<any>(undefined); // temporary OrderValues | undefined
  const [focus, setFocus] = useState<string | undefined>(undefined);
  const [checkoutError, setCheckoutError] = useState<string>("");
  const [paymentsErrors, setPaymentsErrors] = useState<PaymentsErrorsValues>({
    expiryError: null,
    cardError: null,
    cvvError: null,
  });
  const [isShowPopupValid, setIsShowPopupValid] = useState(false);
  const [isPopupErrorCardVisible, setIsPopupErrorCardVisible] = useState(false);
  const [isVisibleExpiredPopup, setIsVisibleExpiredPopup] = useState(false);

  const upsellOrderId = useRef(uuidv4());

  const cartItems = useSelector(selectCartItems);

  const orderedReviews = useMemo(() => {
    const prioritizedKeys = ["NightTime Edibles", "HERS Edibles"];
    const otherKeys = Object.keys(reviews).filter((key) => !prioritizedKeys.includes(key));
    const orderedKeys = [...prioritizedKeys, ...otherKeys];

    return orderedKeys.flatMap((key) => reviews[key]);
  }, [reviews]);

  const [gclid] = useLocalStorageState("mp-gclid");

  const isWizardFlow = location.search.includes("wizard");
  const bedItemsInCart = cartItems.find((item) => !item.isFree && item.product.type === "BED");
  const beltItemsInCart = cartItems.find(
    (item) => !item.isFree && item.product.type === "SEATBELT"
  );

  const setError = (field: keyof typeof paymentsErrors, message: string | null) => {
    setPaymentsErrors((prev) => ({ ...prev, [field]: message }));
  };

  const handleSubmitForm = async (values: CheckoutFormValues): Promise<void> => {
    const submittedCartItems = [...cartItems];

    setOrderLoading(true);
    setCheckoutError("");

    const {
      cardCode,
      cardNumber,
      cardExpiry,
      firstName,
      lastName,
      email,
      phoneNumber,
      streetAddress,
      streetAddress2,
      city,
      state,
      zipCode,
      diffStreetAddress,
      diffStreetAddress2,
      diffCity,
      diffState,
      diffZipCode,
      isSameAddress,
    } = values;

    const [expMonth, expYear] = cardExpiry.split("/");

    const isFreeGiftConditionSatisfied =
      process.env.GATSBY_ENABLE_FREE_GIFT &&
      cartItems.some(
        ({ product }) =>
          isMedBoxProduct(product) &&
          (product.subscriptionType === "EVERY_6_MONTHS" ||
            product.subscriptionType === "EVERY_12_MONTHS")
      ) &&
      submittedCartItems.every(({ product }) => product.slug !== "anti-anxiety-calming-bed");

    if (isFreeGiftConditionSatisfied) {
      submittedCartItems.push(freeGiftItem);
    }

    if ((bedItemsInCart || beltItemsInCart) && isWizardFlow === false) {
      if (
        submittedCartItems.findIndex(
          (cartItem) => cartItem.product.id === groovePlus.product.id
        ) === -1
      ) {
        submittedCartItems.push(groovePlus);
      }
    }

    const products = submittedCartItems.map((cartItem) => ({
      id: cartItem.product.id,
      quantity: cartItem.quantity,
    }));

    const billingAddress = {
      addressLine1: (!isSameAddress ? diffStreetAddress : streetAddress) as string,
      addressLine2: (!isSameAddress ? diffStreetAddress2 : streetAddress2) as string,
      city: !isSameAddress ? diffCity : city,
      zip: !isSameAddress ? diffZipCode : zipCode,
      country: "US",
      state: !isSameAddress ? diffState : state,
    };

    const shippingAddress = {
      addressLine1: streetAddress as string,
      addressLine2: streetAddress2 as string,
      city,
      zip: zipCode,
      country: "US",
      state,
    };

    const customer = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      phone: `${phoneNumber}`.trim(),
      billingAddress,
    };

    const variables = {
      orderId,
      shippingAddress,
      products,
      customer,
      creditCard: {
        cardNumber: cardNumber.replaceAll(" ", ""),
        cardCode,
        expMonth: parseInt(expMonth, 10),
        expYear: parseInt(expYear, 10),
      },
      shippingMethod: shipping,
      coupon: coupon.isApplied ? coupon.value : "",
      metadata: {} as any,
    };

    if (gclid) {
      variables.metadata.adwords_click = gclid;
    }

    if (isWizardFlow) {
      Object.entries(location?.state?.wizardFormValues || {}).forEach(([key, value]) => {
        if (key && value) {
          variables.metadata.wizard[key] = value;
        }
      });
    }

    setOrderValues(variables);

    try {
      if (coupon.isApplied) {
        const { data } = await axios.post(`${window.location.origin}/api/estimate`, {
          coupon: coupon.value,
          shippingMethod: shipping,
          products,
          email,
        });

        if (data?.error_code || data?.coupons?.invalid?.length) {
          setCoupon({
            value: "",
            discount: 0,
            isApplied: false,
            message: { title: "Invalid coupon", type: "ERROR" },
          });

          throw new Error("Invalid Coupon.");
        }
      }

      const isDiffAddress = !values.isSameAddress;
      const customerInfo = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phoneNumber,
        billingAddress: {
          firstName: values.firstName,
          lastName: values.lastName,
          addressLine1: isDiffAddress ? values.diffStreetAddress : values.streetAddress,
          addressLine2: isDiffAddress ? values.diffStreetAddress2 : values.streetAddress2,
          city: isDiffAddress ? values.diffCity : values.city,
          zip: isDiffAddress ? values.diffZipCode : values.zipCode,
          country: "US",
          state: isDiffAddress ? values.diffState : values.state,
          phone: values.phoneNumber,
        },
      };

      const errors = await validateForm(values);

      if (
        Object.keys(errors || {}).length > 0 ||
        Object.values(paymentsErrors).some((error) => error !== null)
      ) {
        if (errors.cardNumber || paymentsErrors.cardError || isShowPopupValid) {
          void formik.setFieldValue("cardNumber", "");
          setIsPopupErrorCardVisible(true);

          setTimeout(() => {
            setIsPopupErrorCardVisible(false);
          }, 3000);
        }

        if (errors.cardExpiry || paymentsErrors.expiryError) {
          void formik.setFieldValue("cardExpiry", "");

          if (paymentsErrors.expiryError !== null) {
            const [month, year] = values.cardExpiry.split("/");

            track("Used expired card", {
              email: customer.email,
              month,
              year,
            });
          }

          setIsVisibleExpiredPopup(true);

          setTimeout(() => {
            setIsVisibleExpiredPopup(false);
          }, 3000);
        }

        setIsShowErrors(true);

        return;
      }

      try {
        void axios.post(`${window.location.origin}/api/customer`, customerInfo);
        // eslint-disable-next-line no-empty
      } catch (err) {}

      gaAddShippingInfo({
        cartItems,
        ecommerce: {
          value: cartSubTotal,
          currency: "USD",
          shipping_tier: "FREE",
          coupon: coupon.value,
        },
        user_data: {
          phone_number: values?.phoneNumber?.replace(/\D/g, ""),
          email_address: values.email,
          address: {
            city: values.city,
            address: values.streetAddress,
            address2: values.streetAddress2,
            state: values.state,
            country: "US",
            postal_code: values.zipCode,
            first_name: values.firstName,
            last_name: values.lastName,
          },
        },
      });

      const { data } = await axios.post(`${window.location.origin}/api/checkout`, variables);

      if (data?.message) {
        throw new Error(data.message);
      }

      if (data) {
        trackPlacedOrder({
          cartItems: submittedCartItems,
          orderId,
          email,
          userInfo: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            city,
            country: "US",
            phoneNumber,
            region: state,
            zip: zipCode,
          },
          billingAddress,
          shippingAddress,
          coupon: coupon.isApplied ? coupon.value : "",
          discount: coupon.discount,
          shippingMethod: shipping,
        });

        const gaUserData = {
          user_id: data.customer_id || null,
          phone_number: customer.phone.replace(/\D/g, ""),
          email_address: customer.email,
          address: {
            city,
            address: streetAddress,
            address2: streetAddress2,
            state,
            country: "US",
            postal_code: zipCode,
            first_name: customer.firstName,
            last_name: customer.lastName,
          },
        };

        gaAddPaymentInfo({
          cartItems: submittedCartItems,
          ecommerce: {
            payment_type: "Credit Card",
            value: data.amount,
            currency: "USD",
            coupon: coupon.isApplied ? coupon.value : null,
          },
          user_data: gaUserData,
        });

        gaPurchase({
          cartItems: submittedCartItems,
          ecommerce: {
            transaction_id: data.transaction_id || null,
            value: data.amount,
            shipping: shipping === "FREE" ? 0 : 14.99,
            currency: "USD",
            coupon: coupon.isApplied ? coupon.value : null,
          },
          user_data: gaUserData,
        });
      }

      setOrder(!!data);
    } catch (error: any) {
      if (error?.response?.data?.includes("quantity must be")) {
        setCheckoutError("The quantity of one unit of the product must not exceed 20");
      } else if (!error?.message?.includes("Invalid Coupon")) {
        setCheckoutError(error?.response?.data || error?.message);
      }
    } finally {
      setOrderLoading(false);
    }
  };

  const validateExpiryDate = (month: string, year: string) => {
    const currentDate = new Date();
    const expiryDate = new Date(`20${year}`, month - 1);

    const isCurrentMonth =
      currentDate.getFullYear() === Number(`20${year}`) && currentDate.getMonth() === +month - 1;

    if (expiryDate < currentDate && !isCurrentMonth) {
      setError("expiryError", "Please enter a valid expiration date");
    } else {
      setError("expiryError", null);
    }
  };

  const formik = useFormik<CheckoutFormValues>({
    onSubmit: handleSubmitForm,
    validateOnMount: false,
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      streetAddress: "",
      streetAddress2: "",
      city: "",
      zipCode: "",
      state: "",
      phoneNumber: "",
      country: "United States (US)", // For inputs, but we'll always send "US" as country
      isSameAddress: true,
      diffCity: "",
      diffCountry: "United States (US)", // For inputs, but we'll always send "US" as diffCountry
      diffState: "",
      diffStreetAddress: "",
      diffStreetAddress2: "",
      diffZipCode: "",
      cardNumber: "",
      cardExpiry: "",
      cardCode: "",
    },
    validationSchema,
  });

  const { handleSubmit, values, validateForm, errors } = formik;

  const { email, cardNumber, cardCode } = values;

  const validateCVV = useCallback(
    (cvv: string) => {
      const firstDigit = cardNumber[0];

      let errorMessage = "";

      if (firstDigit === "3" && cvv.length < 4) {
        errorMessage =
          "CVV should be 4 digits for your card type. Please check the card and try again.";
      } else if (["4", "5", "6"].includes(firstDigit) && cvv.length > 3) {
        errorMessage =
          "CVV should only be 3 digits for your card type. Please check the card and try again.";
      }

      setError("cvvError", errorMessage || null);
    },
    [cardNumber]
  );

  useEffect(() => {
    yup
      .string()
      .email("Invalid email")
      .validate(email)
      .then(() => {
        setEmail(email);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    const isValid = isValidFirstDigit(cardNumber);
    const isShow = !isValid && cardNumber.length > 0;

    setIsShowPopupValid(isShow);
  }, [cardNumber]);

  useEffect(() => {
    validateCVV(cardCode);
  }, [cardCode, cardNumber, validateCVV]);

  useEffect(() => {
    if (order && values) {
      void navigate("/thankyou", {
        state: {
          values,
          cartItems,
          coupon,
          cartTotal,
          cartSubTotal: oldTotalCart,
          shippingPrice,
          orderValues,
          upsellOrderId: upsellOrderId.current,
        },
      });
    }
  }, [cartItems, cartSubTotal, cartTotal, coupon, order, orderValues, shippingPrice, values]);

  useEffect(() => {
    void validateForm(values).then((errors) => {
      const filteredErrors = Object.keys(errors).filter(
        (e) => !["cardCode", "cardNumber", "cardExpiry"].includes(e)
      );

      if (formType === "payment" && filteredErrors.length > 0) {
        setFormType("information");
      }
    });
  }, []);

  const [isShowErrors, setIsShowErrors] = useState(false);

  return (
    <div className="checkout-form-container">
      <FormikProvider value={formik}>
        {/* <FormTypeBreadcrumb formType={formType} setFormType={() => handleChangeFormType(true)} /> */}

        {/* <Timer time={time} setTime={setTime} icon="circle-check-mark" color="green" /> */}

        {/* <FormErrors error={checkoutError} formType={formType} isShowErrors={isShowErrors} /> */}

        <Form onSubmit={handleSubmit} className="checkout-form">
          <CheckoutInformationForm
            cartItems={cartItems}
            formType={formType}
            focus={focus}
            setFocus={setFocus}
            orderId={orderId}
            isShowErrors={isShowErrors}
          />

          <CheckoutPaymentForm
            formType={formType}
            setFormType={setFormType}
            shipping={shipping}
            setFocus={setFocus}
            handleChangeShipping={handleChangeShipping}
            validateExpiryDate={validateExpiryDate}
            paymentsErrors={paymentsErrors}
            setError={setError}
            isShowPopupValid={isShowPopupValid}
            isShowErrors={isShowErrors}
            isPopupErrorCardVisible={isPopupErrorCardVisible}
            isVisibleExpiredPopup={isVisibleExpiredPopup}
          />

          <CheckoutPaymentBtn
            onClick={() => handleSubmitForm(values)}
            type="submit"
            text={orderLoading ? "Loading..." : "Complete order"}
            time={time}
            disabled={orderLoading}
            style={{ marginTop: "24px" }}
            errors={errors}
            paymentErrors={paymentsErrors}
            checkoutError={checkoutError}
            isShowErrors={isShowErrors}
          />

          <MiniReview reviewData={orderedReviews} />
          <div className="moneyback">
            <Icon name="shop" />
            <h1>60 day money-back guarantee</h1>
            <p>Return your items within 60 days for a full refund</p>
          </div>

          <CheckoutGuarantees />

          <CheckoutLeave time={time} location={location} />
        </Form>
      </FormikProvider>
    </div>
  );
};

export default CheckoutFormContainer;
