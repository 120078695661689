import { CartItem } from "@typings/cart";
import React from "react";

interface CartItemTotalProps {
  cartItem: CartItem;
}

const CartItemTotal = ({ cartItem }: CartItemTotalProps) => {
  const { product, quantity, isFree } = cartItem;

  return (
    <div className="cart-item__total">
      {!isFree ? (
        <React.Fragment>
          <div className="cart-item__total-price__wrapper">
            <span className="cart-item__total-quantity">{quantity} x </span>
            <span className="cart-item__total-price">${product.price}</span>
          </div>

          {product.oldPrice && (
            <span className="cart-item__total-old-price">${product.oldPrice}</span>
          )}
        </React.Fragment>
      ) : (
        <div className="cart-item__total-price__wrapper">
          <span className="cart-item__total-quantity">{quantity} x </span>
          <span className="cart-item__total-price">FREE</span>
        </div>
      )}
    </div>
  );
};

export default CartItemTotal;
