import getInformation from "@helpers/information/getInformation";
import { CartItem } from "@typings/cart";

const FreeBedNotifierCartItem: CartItem = {
  quantity: 1,
  isFree: true,
  product: {
    ...getInformation({ name: "FREE Cannabis Flower" }),
    image: "/images/beds/beige.png",
    name: "FREE Dog Bed Unlocked!",
    additionalDescription: "Click to choose the bed",
  },
};

export const FREE_NOTIFIERS_MAP = {
  BED: FreeBedNotifierCartItem,
};
