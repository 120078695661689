import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { trackAddedToCart } from "@analytics/klaviyo";
import { gaAddToCart, gaRemoveFromCart } from "@analytics/google";

import {
  AddToCartActionPayload,
  CartState,
  RemoveFromCartActionPayload,
} from "./types/cartSliceState";

import { addCartItems } from "./utils/items/addCartItems";
import { removeCartItems } from "./utils/items/removeCartItems";

import { applyPromos } from "./utils/promos/applyPromos";

const initialState: CartState = {
  cartItems: [],
  isCartOpen: false,
  isCartLoaded: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    getItemsFromLocalStorage: (state) => {
      state.cartItems = JSON.parse(localStorage.getItem("cartItems") || "[]");
      state.isCartLoaded = true;
    },

    getItemsFromURL: (state) => {
      if (state.cartItems.length === 0) {
        const urlParams = new URLSearchParams(window.location.search);
        const productsParam = decodeURIComponent(urlParams.get("products") || "[]");

        if (productsParam) {
          const cartItems = JSON.parse(productsParam);

          if (Array.isArray(cartItems)) {
            state.cartItems = cartItems;
            state.isCartLoaded = true;
          }
        }

        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },

    /* Modal Actions
     ============== */
    openCart: (state) => {
      state.isCartOpen = true;
    },

    closeCart: (state) => {
      state.isCartOpen = false;
    },

    toggleCart: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },

    /* Cart Actions
     ============== */
    addToCart: (state, action: PayloadAction<AddToCartActionPayload>) => {
      const addedCartItems = action.payload.cartItems;

      state.cartItems = addCartItems(state.cartItems, addedCartItems);
      state.cartItems = applyPromos(state.cartItems);

      trackAddedToCart(state.cartItems, addedCartItems);
      gaAddToCart(addedCartItems);

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    removeFromCart: (state, action: PayloadAction<RemoveFromCartActionPayload>) => {
      const deletedCartItems = action.payload.cartItems;

      state.cartItems = removeCartItems(state.cartItems, deletedCartItems);
      state.cartItems = applyPromos(state.cartItems);

      if (!state.cartItems.length) state.isCartOpen = false;

      gaRemoveFromCart(deletedCartItems);

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    clearCart: (state) => {
      state.cartItems = [];

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
  },
});

export default cartSlice;
