import information from "@data/information.json";
import { Information, InformationJson } from "scripts/generateInformationJson";

const typedInformation = information as InformationJson;

export default (productInformation: Partial<Information>) => {
  return Object.values(typedInformation).filter((informationEl) => {
    const productInformationKeys = Object.keys(productInformation) as (keyof Information)[];

    return productInformationKeys.every((key) => informationEl[key] === productInformation[key]);
  });
};
